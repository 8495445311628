import {
	mdiDrawing, mdiHumanMaleChild, mdiTranslate,
} from '@mdi/js';
import { ENGLISH_1_2, SHRR, YASLI } from './ids';
import logoped from './logoped';
import { CatalogItem } from './types';

const items: CatalogItem[] = [
	{
		id: YASLI,
		title: 'Ясли', // 1-2
		age: '1-2',
		icon: mdiHumanMaleChild,
		description: 'Развивающий досуг для детей с 8 месяцев',
		prices: [
			{
				title: 'День',
				price: 1200,
			}, {
				title: 'Абонемент на месяц (с субсидией)',
				price: 17000,
				monthly: true,
			}, {
				title: 'Абонемент на месяц (без субсидий)',
				price: 21000,
				monthly: true,
			},
		],
		route: 'yasli',
		schedule: {
			mon: '9:00 – 12:00',
			tue: '9:00 – 12:00',
			wed: '9:00 – 12:00',
			thu: '9:00 – 12:00',
			fri: '9:00 – 12:00',
		},
	}, {
		id: ENGLISH_1_2,
		title: 'Английский язык', // 1-2
		age: '1-2',
		icon: mdiTranslate,
		description: 'Путешествие в волшебную страну «Букваляндия» – это знакомство с миром английского языка',
		prices: [
			{
				title: 'Групповое занятие (30 мин) / Разовое посещение',
				price: 750,
				count: 1,
			}, {
				title: 'Групповое занятие (30 мин) / Абонемент на 4 занятия',
				price: 2700,
				count: 4,
			}, {
				title: 'Групповое занятие (30 мин) / Абонемент на 8 занятий',
				price: 5100,
				count: 8,
			}, {
				title: 'Групповое занятие (30 мин) / Абонемент на 24 занятия',
				price: 14400,
				count: 24,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Разовое посещение',
				price: 1089,
				count: 1,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Абонемент на 4 занятия',
				price: 3920,
				count: 4,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Абонемент на 12 занятий',
				price: 7405,
				count: 8,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Абонемент на 24 занятия',
				price: 20908,
				count: 24,
			},
		],
		schedule: {
			tue: '17:15 – 18:00',
			thu: '17:15 – 18:00',
			sat: '9:30 - 10:15',
		},
		route: 'english-group-1',
	},
	logoped,
	{
		id: SHRR,
		title: 'Школа раннего развития', // 1-2
		age: '1-2',
		description: 'Комплекс игр и упражнений для всестороннего развития ребенка',
		icon: mdiDrawing,
		prices: [
			{
				title: 'Разовое занятие',
				price: 645,
				count: 1,
			}, {
				title: 'Абонемент на 4 занятия',
				price: 2178,
				count: 4,
			}, {
				title: 'Абонемент на 8 занятий',
				price: 4114,
				count: 8,
			}, {
				title: 'Абонемент на 24 занятия',
				price: 12314,
				count: 24,
			},
		],
		route: 'shkola-rannego-razvitiya',
		schedule: {
			mon: '17:00',
			fri: '11:00',
		},
	},
];

export default items;
