import {
	mdiHumanMaleChild, mdiTranslate,
} from '@mdi/js';
import { DETSKIY_SAD, ENGLISH_3_5 } from './ids';
import logoped from './logoped';
import podgotovkaKShkole from './podgotovka-k-shkole';
import shkolaZdoroviya1 from './shkola-zdoroviya1';
import { CatalogItem } from './types';

const items: CatalogItem[] = [
	{
		id: DETSKIY_SAD,
		title: 'Детский сад', // 3-5
		description: 'Адаптивная программа и семейный подход к развитию детей',
		age: '3-5',
		prices: [
			{
				title: 'День',
				price: 1900,
			}, {
				title: 'Абонемент на месяц (с субсидией)',
				price: 26000,
				monthly: true,
			}, {
				title: 'Абонемент на месяц (без субсидий)',
				price: 35800,
				monthly: true,
			}, {
				title: 'Клубная карта на месяц',
				price: 13000,
				monthly: true,
			},
		],
		icon: mdiHumanMaleChild,
		route: 'detskiy-sad-group-2',
		schedule: {
			mon: '8:00 – 19:00',
			tue: '8:00 – 19:00',
			wed: '8:00 – 19:00',
			thu: '8:00 – 19:00',
			fri: '8:00 – 19:00',
		},
	}, {
		id: ENGLISH_3_5,
		title: 'Английский язык',
		age: '3-5',
		icon: mdiTranslate,
		description: 'Вторая ступень обучения английскому языку',
		prices: [
			{
				title: 'Групповое занятие (45 мин) / Разовое посещение',
				price: 800,
				count: 1,
			}, {
				title: 'Групповое занятие (45 мин) / Абонемент на 4 занятия',
				price: 2880,
				count: 4,
			}, {
				title: 'Групповое занятие (45 мин) / Абонемент на 8 занятий',
				price: 5440,
				count: 8,
			}, {
				title: 'Групповое занятие (45 мин) / Абонемент на 24 занятия',
				price: 15360,
				count: 24,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Разовое посещение',
				price: 1089,
				count: 1,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Абонемент на 4 занятия',
				price: 3920,
				count: 4,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Абонемент на 12 занятий',
				price: 7405,
				count: 8,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Абонемент на 24 занятия',
				price: 20908,
				count: 24,
			},
		],
		route: 'english-group-2',
		schedule: {
			tue: '18:15 - 19:00',
			thu: '18:15 - 19:00',
			sat: '10:00 - 10:45',
		},
	},
	logoped,
	podgotovkaKShkole,
	shkolaZdoroviya1,
];

export default items;
