import { mdiHumanMaleChild, mdiTranslate } from '@mdi/js';
import logoped from './logoped';
import podgotovkaKShkole from './podgotovka-k-shkole';
import shkolaZdoroviya1 from './shkola-zdoroviya1';
import { CatalogItem } from './types';

export default [
	{
		id: 10,
		title: 'Детский сад', // 3-5
		description: 'Развивающая программа с английским языком',
		age: '6-7',
		prices: [
			{
				title: 'День',
				price: 1900,
			}, {
				title: 'Абонемент на месяц (с субсидией)',
				price: 27300,
				monthly: true,
			}, {
				title: 'Абонемент на месяц (без субсидий)',
				price: 34900,
				monthly: true,
			}, {
				title: 'Клубная карта на месяц',
				price: 11550,
				monthly: true,
			},
		],
		icon: mdiHumanMaleChild,
		route: 'detskiy-sad-group-2',
		schedule: {
			mon: '8:00 – 19:00',
			tue: '8:00 – 19:00',
			wed: '8:00 – 19:00',
			thu: '8:00 – 19:00',
			fri: '8:00 – 19:00',
		},
	}, {
		id: 11,
		title: 'Английский язык',
		age: '6-7',
		icon: mdiTranslate,
		description: 'Языковая поддержка для совершенствования знаний английского языка: грамматики и лексики',
		prices: [
			{
				title: 'Групповое занятие (45 мин) / Разовое посещение',
				price: 660,
				count: 1,
			}, {
				title: 'Групповое занятие (45 мин) / Абонемент на 4 занятия',
				price: 2615,
				count: 4,
			}, {
				title: 'Групповое занятие (45 мин) / Абонемент на 8 занятий',
				price: 4935,
				count: 8,
			}, {
				title: 'Групповое занятие (45 мин) / Абонемент на 24 занятия',
				price: 13935,
				count: 24,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Разовое посещение',
				price: 1089,
				count: 1,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Абонемент на 4 занятия',
				price: 3920,
				count: 4,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Абонемент на 12 занятий',
				price: 7405,
				count: 8,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Абонемент на 24 занятия',
				price: 20908,
				count: 24,
			},
		],
		route: 'english-group-3',
		schedule: {
			tue: '19:00',
			wed: '19:00',
			sat: '13:00',
		},
	},
	logoped,
	podgotovkaKShkole,
	shkolaZdoroviya1,
] as CatalogItem[];
